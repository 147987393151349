import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb';
import { Button } from '@twilio-paste/button';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Card } from '@twilio-paste/card';
import { FormInput, FormLabel, FormHelpText, Select, Option } from '@twilio-paste/form';
import { Grid, Column } from '@twilio-paste/grid';
import { Heading } from '@twilio-paste/heading';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@twilio-paste/tabs';
import { Text } from '@twilio-paste/text';
import { Toast } from '@twilio-paste/toast';
import { ErrorIcon } from "@twilio-paste/icons/esm/ErrorIcon";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import { Codeblock } from '../../../components/codeblock';
import { useUID } from 'react-uid';
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { defaultExample, initialFocusExample, wideExample } from '../../../component-examples/ModalExamples';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/patterns/contribute/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <Breadcrumb mdxType="Breadcrumb">
  <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
  <BreadcrumbItem to="/patterns" mdxType="BreadcrumbItem">Patterns</BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" variant="heading10" mdxType="Heading">{props.pageContext.frontmatter.title}</Heading>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <p>{`Thanks for your interest in contributing patterns! Since this is a general guideline, feel free to grow or shrink this process however you need.`}</p>
        <h2>{`General process`}</h2>
        <ol>
          <li parentName="ol">{`Write a `}<a parentName="li" {...{
              "href": "https://docs.google.com/document/d/1SSIURVMzlGbANyvtYlU61ZdDaSqF2QigFdFZuSnYSkk/edit"
            }}>{`short 🩳  spec`}</a>{` and design your pattern(s). The pattern could potentially be something you’re already working on. Here are some `}<a parentName="li" {...{
              "href": "https://drive.google.com/drive/folders/1zae55uF3M-s4QUDsGMtwHLbmi31wmJ54?usp=sharing"
            }}>{`examples of other specs`}</a>{` people have written.`}</li>
          <li parentName="ol">{`Review the spec with us, your crit pod(s), and the Design Systems Committee`}</li>
          <li parentName="ol">{`If you have time or are already planning to do usability testing on your product feature, test the pattern with users (be sure to tap into the PD org to see    if research exists already)`}</li>
          <li parentName="ol">{`Add it to the Paste site for all to use and reference 🎉`}</li>
        </ol>
        <p>{`Have feedback on the process? Add to `}<a parentName="p" {...{
            "href": "https://docs.google.com/document/d/1N_edYt6O9_2DpJi0kwvU0Fjaxur-voEOi89YzP2qyOE/edit#"
          }}>{`our feedback doc`}</a>{`.`}</p>
        <h2>{`Roles`}</h2>
        <h3>{`Design System’s role`}</h3>
        <p>{`We’re here to partner with you throughout the pattern process to ensure you’re building something that is systemized and accessible. We’ll also help you add to the docs site, refine design assets, and create coded examples. `}</p>
        <h3>{`Your role (anyone at Twilio!)`}</h3>
        <p>{`You’ll create and research the doc/pattern/artifact and socialize it within your crit pods, committees, and peers. PDs have great insight into product-specific customer problems, which means if you’re a PD, you’re especially primed to contribute a new pattern or update an existing one.`}</p>
        <h2>{`Contribution types`}</h2>
        <p>{`Patterns are for you and your teammates, so it’s up to you what scope and type of contribution will have the most impact.`}</p>
        <h3>{`Ways you can contribute`}</h3>
        <ul>
          <li parentName="ul">{`Creating a full, net-new pattern`}</li>
          <li parentName="ul">{`Creating a variation of a pattern`}</li>
          <li parentName="ul">{`Documenting one part of a pattern`}</li>
          <li parentName="ul">{`Sharing research related to a pattern`}</li>
          <li parentName="ul">{`Refining an existing pattern based on new information`}</li>
        </ul>
        <h3>{`Where do I start?`}</h3>
        <p>{`Check out our `}<a parentName="p" {...{
            "href": "/patterns#patterns"
          }}>{`list of most requested patterns`}</a>{` to see what's available. `}<strong>{`Have a new idea?`}</strong>{` Reach out to your peers and create a `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/discussions"
          }}>{`Github Discussion`}</a>{` to see if there’s a need for something else.`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      